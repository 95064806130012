



































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { TranslateResult } from "vue-i18n";
import {
  ReportIlustration,
  PaymentsIlustration,
  Payments2Ilustration,
  EmailIlustration,
  Email2Ilustration,
  BoxesIlustration,
  ExperimentIlustration,
} from "./ilustrations";
@Component({
  components: {
    ReportIlustration,
    PaymentsIlustration,
    Payments2Ilustration,
    EmailIlustration,
    Email2Ilustration,
    BoxesIlustration,
    ExperimentIlustration,
  },
})
export default class Process extends Vue {
  processItems = [
    {
      side: "right",
      name: "boxes",
      header: this.$t(
        "mainPage.processContent.firstStep.header"
      ) as TranslateResult,
      description: this.$t(
        "mainPage.processContent.firstStep.description"
      ) as TranslateResult,
    },
    {
      side: "left",
      name: "email-2",
      header: this.$t(
        "mainPage.processContent.secondStep.header"
      ) as TranslateResult,
      description: this.$t(
        "mainPage.processContent.secondStep.description"
      ) as TranslateResult,
    },
    {
      side: "right",
      name: "payments-2",
      header: this.$t(
        "mainPage.processContent.thirdStep.header"
      ) as TranslateResult,
      description: this.$t(
        "mainPage.processContent.thirdStep.description"
      ) as TranslateResult,
    },
    {
      side: "left",
      name: "experiment",
      header: this.$t(
        "mainPage.processContent.fourthStep.header"
      ) as TranslateResult,
      description: this.$t(
        "mainPage.processContent.fourthStep.description"
      ) as TranslateResult,
    },
    {
      side: "right",
      name: "report",
      header: this.$t(
        "mainPage.processContent.fifthStep.header"
      ) as TranslateResult,
      description: this.$t(
        "mainPage.processContent.fifthStep.description"
      ) as TranslateResult,
    },
  ];

  @Watch("$store.state.language")
  onPropertyChanged(value: string, oldValue: string) {
    this.processItems = [
      {
        side: "right",
        name: "boxes",
        header: this.$t(
          "mainPage.processContent.firstStep.header"
        ) as TranslateResult,
        description: this.$t(
          "mainPage.processContent.firstStep.description"
        ) as TranslateResult,
      },
      {
        side: "left",
        name: "email-2",
        header: this.$t(
          "mainPage.processContent.secondStep.header"
        ) as TranslateResult,
        description: this.$t(
          "mainPage.processContent.secondStep.description"
        ) as TranslateResult,
      },
      {
        side: "right",
        name: "payments-2",
        header: this.$t(
          "mainPage.processContent.thirdStep.header"
        ) as TranslateResult,
        description: this.$t(
          "mainPage.processContent.thirdStep.description"
        ) as TranslateResult,
      },
      {
        side: "left",
        name: "experiment",
        header: this.$t(
          "mainPage.processContent.fourthStep.header"
        ) as TranslateResult,
        description: this.$t(
          "mainPage.processContent.fourthStep.description"
        ) as TranslateResult,
      },
      {
        side: "right",
        name: "report",
        header: this.$t(
          "mainPage.processContent.fifthStep.header"
        ) as TranslateResult,
        description: this.$t(
          "mainPage.processContent.fifthStep.description"
        ) as TranslateResult,
      },
    ];
  }

  isLeft(side) {
    return side === "left";
  }

  isRight(side) {
    return side === "right";
  }

  properIlustrationComponent(name) {
    return `${name}-ilustration`;
  }
}
