












import { Component, Vue, Watch } from "vue-property-decorator";
import { TranslateResult } from "vue-i18n";
@Component({})
export default class AboutUsMain extends Vue {
  items = [
    {
      id: 1,
      icon: "microscope",
      header: this.$t(
        "mainPage.advantagesContent.modernity.header"
      ) as TranslateResult,
      description: this.$t(
        "mainPage.advantagesContent.modernity.description"
      ) as TranslateResult
    },
    {
      id: 2,
      icon: "calendar-check",
      header: this.$t(
        "mainPage.advantagesContent.reliability.header"
      ) as TranslateResult,
      description: this.$t(
        "mainPage.advantagesContent.reliability.description"
      ) as TranslateResult
    },
    {
      id: 3,
      icon: "briefcase",
      header: this.$t(
        "mainPage.advantagesContent.experience.header"
      ) as TranslateResult,
      description: this.$t(
        "mainPage.advantagesContent.experience.description"
      ) as TranslateResult
    },
    {
      id: 4,
      icon: "hard-hat",
      header: this.$t(
        "mainPage.advantagesContent.safety.header"
      ) as TranslateResult,
      description: this.$t(
        "mainPage.advantagesContent.safety.description"
      ) as TranslateResult
    }
  ];

  @Watch("$store.state.language")
  onPropertyChanged(value: string, oldValue: string) {
    this.items = [
      {
        id: 1,
        icon: "microscope",
        header: this.$t(
          "mainPage.advantagesContent.modernity.header"
        ) as TranslateResult,
        description: this.$t(
          "mainPage.advantagesContent.modernity.description"
        ) as TranslateResult
      },
      {
        id: 2,
        icon: "calendar-check",
        header: this.$t(
          "mainPage.advantagesContent.reliability.header"
        ) as TranslateResult,
        description: this.$t(
          "mainPage.advantagesContent.reliability.description"
        ) as TranslateResult
      },
      {
        id: 3,
        icon: "briefcase",
        header: this.$t(
          "mainPage.advantagesContent.experience.header"
        ) as TranslateResult,
        description: this.$t(
          "mainPage.advantagesContent.experience.description"
        ) as TranslateResult
      },
      {
        id: 4,
        icon: "hard-hat",
        header: this.$t(
          "mainPage.advantagesContent.safety.header"
        ) as TranslateResult,
        description: this.$t(
          "mainPage.advantagesContent.safety.description"
        ) as TranslateResult
      }
    ];
  }
}
