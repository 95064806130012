import { render, staticRenderFns } from "./AboutUsMain.vue?vue&type=template&id=48ad83f4&"
import script from "./AboutUsMain.vue?vue&type=script&lang=ts&"
export * from "./AboutUsMain.vue?vue&type=script&lang=ts&"
import style0 from "./AboutUsMain.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports