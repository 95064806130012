import Vue from 'vue';
import Vuex from 'vuex';
import { Locales } from '@/translations/locales';
import { defaultLocale } from '@/translations';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    language: defaultLocale,
    isModalOpen: false,
  },
  mutations: {
    SET_LANGUAGE: (state, payload: Locales) => {
      state.language = payload
    },
    SET_MODAL: (state, payload: boolean) => {
      state.isModalOpen = payload;
    }
  },
  actions: {},
  modules: {}
});
