





























































































import { Component, Vue } from "vue-property-decorator";
import { LOCALES } from "@/translations/locales";
import { defaultLocale } from "@/translations";
import { i18n } from "@/main";

@Component
export default class Menu extends Vue {
  isOpen = false;

  updateLanguage() {
    this.$store.commit("SET_MODAL", true);
  }

  get isPolish() {
    return i18n.locale === "pl";
  }

  get isEnglish() {
    return i18n.locale === "en";
  }

  get phoneNumbers() {
    return "792 045 333 </br> 536 796 043";
  }

  toogleMenu() {
    this.isOpen = !this.isOpen;
  }

  closeMenu() {
    this.isOpen = false;
  }
}
