





















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import OfferComponent from "./OfferComponent.vue";
import { TranslateResult } from "vue-i18n";

@Component({
  components: {
    OfferComponent
  }
})
export default class OffersComponent extends Vue {
  @Prop({ default: false })
  isPriceList;

  offers = [
    {
      id: 1,
      containerId: "tilt1",
      icon: "balance-scale",
      imageClass: "police",
      header: this.$t(
        "offersContent.processingAndJustice.header"
      ) as TranslateResult,
      path: "/oferta/organy-scigania-i-wymiar-sprawiedliwosci"
    },
    {
      id: 2,
      containerId: "tilt2",
      icon: "building",
      imageClass: "company",
      header: this.$t("offersContent.companies.header") as TranslateResult,
      path: "/oferta/firmy-i-instytucje"
    },
    {
      id: 3,
      containerId: "tilt3",
      icon: "user-tie",
      imageClass: "private",
      header: this.$t("offersContent.private.header") as TranslateResult,
      path: "/oferta/osoby-prywatne"
    }
  ];

  offersPriceList = [
    {
      id: 1,
      containerId: "tilt1",
      icon: "balance-scale",
      imageClass: "police",
      header: this.$t(
        "piceListContent.processingAndJustice.header"
      ) as TranslateResult,
      path: "/cennik/organy-scigania-i-wymiar-sprawiedliwosci"
    },
    {
      id: 2,
      containerId: "tilt2",
      icon: "building",
      imageClass: "company-and-person",
      header: this.$t(
        "piceListContent.companiesAndPrivate.header"
      ) as TranslateResult,
      path: "/cennik/firmy-i-osoby-prywatne"
    }
  ];

  @Watch("$store.state.language")
  onPropertyChanged(value: string, oldValue: string) {
    this.offers = [
      {
        id: 1,
        containerId: "tilt1",
        icon: "balance-scale",
        imageClass: "police",
        header: this.$t(
          "offersContent.processingAndJustice.header"
        ) as TranslateResult,
        path: "/oferta/organy-scigania-i-wymiar-sprawiedliwosci"
      },
      {
        id: 2,
        containerId: "tilt2",
        icon: "building",
        imageClass: "company",
        header: this.$t("offersContent.companies.header") as TranslateResult,
        path: "/oferta/firmy-i-instytucje"
      },
      {
        id: 3,
        containerId: "tilt3",
        icon: "user-tie",
        imageClass: "private",
        header: this.$t("offersContent.private.header") as TranslateResult,
        path: "/oferta/osoby-prywatne"
      }
    ];

    this.offersPriceList = [
      {
        id: 1,
        containerId: "tilt1",
        icon: "balance-scale",
        imageClass: "police",
        header: this.$t(
          "piceListContent.processingAndJustice.header"
        ) as TranslateResult,
        path: "/cennik/organy-scigania-i-wymiar-sprawiedliwosci"
      },
      {
        id: 2,
        containerId: "tilt2",
        icon: "building",
        imageClass: "company-and-person",
        header: this.$t(
          "piceListContent.companiesAndPrivate.header"
        ) as TranslateResult,
        path: "/cennik/firmy-i-osoby-prywatne"
      }
    ];
  }

  get pageOffers() {
    return this.isPriceList ? this.offersPriceList : this.offers;
  }
}
