import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import OfferPage from '../views/OfferPage.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/o-nas',
    name: 'About',
    component: () => import('../views/AboutUsPage.vue')
  },
  {
    path: '/oferta',
    name: 'Oferta',
    component: OfferPage
  },
  {
    path: '/oferta/:offerName',
    name: 'Spersonalizowana offerta',
    component: () => import('../views/OfferDetailsPage.vue')
  },
  {
    path: '/cennik',
    name: 'Cennik',
    component: () => import('../views/PriceListPage.vue')
  },
  {
    path: '/cennik/:priceListName',
    name: 'Spersonalizowany cennik',
    component: () => import('../views/PriceListDetailsPage.vue')
  },
  {
    path: '/aktualnosci',
    name: 'Aktualnosci',
    component: () => import('../views/NewsPage.vue')
  },
  {
    path: '/praca',
    name: 'Praca',
    component: () => import('../views/WorkPage.vue')
  },
  {
    path: '/informacje',
    name: 'Informacje',
    component: () => import('../views/InfoPage.vue')
  },
  {
    path: '/informacje/warunki',
    name: 'Warunki',
    component: () => import('../views/ConditionsPage.vue')
  },
  {
    path: '/informacje/rodo',
    name: 'Rodo',
    component: () => import('../views/RodoPage.vue')
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: () => import('../views/ContactPage.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
