<template>
  <div>
    <FrontContainer/>
    <AboutUsMain/>
    <Process/>
    <MaterialsContainer/>
    <SwiperGallery/>
    <ResultsMain/>
    <ContactBottom/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import RibbonItems from "@/components/RibbonItems.vue";
import MaterialsContainer from "@/components/MaterialsContainer.vue";
import FrontContainer from "@/components/FrontContainer.vue";
import AboutUsMain from "@/components/AboutUsMain.vue";
import SwiperGallery from "@/components/SwiperGallery.vue";
import ResultsMain from "@/components/ResultsMain.vue";
import ContactBottom from "@/components/ContactBottom.vue";
import Process from "@/components/Process.vue";

export default {
  name: "Home",
  components: {
    MaterialsContainer,
    FrontContainer,
    AboutUsMain,
    SwiperGallery,
    ResultsMain,
    ContactBottom,
    Process
  }
};
</script>

<style>
.home {
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/tlo2.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
