













import { Component, Vue, Prop } from "vue-property-decorator";
import RibbonItem from "./RibbonItem.vue";
@Component({
  components: {
    RibbonItem,
  },
})
export default class RibbonItems extends Vue {
 @Prop()
 ribbons; 
}
