




















































import { Component, Vue } from "vue-property-decorator";
@Component({})
export default class ContactBottom extends Vue {
  contactItems = [
    { id: 1, iconName: "phone-alt", text: "792 045 333 </br> 536 796 043" },
    { id: 2, iconName: "at", text: "lab4tox@lab4tox.pl" },
    {
      id: 3,
      iconName: "map-marker-alt",
      text: "ul. M. Skłodowskiej-Curie 55/61, 50-369 Wrocław, pok.121"
    },
    { id: 4, iconName: "fax", text: "FAX: (+48) 71 750 60 87" }
  ];
}
