










import { Component, Vue, Prop } from "vue-property-decorator";
import router from "../router";

@Component({})
export default class OfferComponent extends Vue {
  @Prop()
  icon;

  @Prop()
  imageClass;

  @Prop()
  header;

  @Prop()
  containerId;

  @Prop()
  routePath;

  @Prop()
  numberOfItems;

  el;

  height = 0;

  width = 0;

  mounted() {
    this.el = document.getElementById(`${this.containerId}`);
    this.height = this.el.clientHeight;
    this.width = this.el.clientWidth;
    this.el.addEventListener("mousemove", this.handleMove);
    this.el.addEventListener("mouseout", this.outMove);
    this.el.addEventListener("mousedown", this.mouseDown);
    this.el.addEventListener("mouseup", this.mouseUp);
  }

  handleMove(e) {
    const xVal = e.layerX;
    const yVal = e.layerY;
    const yRotation = 10 * ((xVal - this.width / 2) / this.width);
    const xRotation = -10 * ((yVal - this.height / 2) / this.height);
    const string =
      "perspective(500px) scale(1) rotateX(" +
      xRotation +
      "deg) rotateY(" +
      yRotation +
      "deg)";
    this.el.style.transform = string;
  }
  outMove(e) {
    const string = "perspective(500px) scale(1) rotateX(0) rotateY(0)";
    this.el.style.transform = string;
  }

  mouseDown(e) {
    const string = "perspective(500px) scale(0.9) rotateX(0) rotateY(0)";
    this.el.style.transform = string;
  }

  mouseUp(e) {
    const string = "perspective(500px) scale(1.1) rotateX(0) rotateY(0)";
    this.el.style.transform = string;
  }

  navigate() {
    router.push({ path: this.routePath });
  }

  get itemsNumber() {
    return this.numberOfItems === 2 ? "two-items" : "";
  }
}
