





























import { Component, Vue } from "vue-property-decorator";
import { i18n } from "@/main";

@Component({})
export default class Modal extends Vue {
  public onClose(): void {
    this.$store.commit("SET_MODAL", false);
  }

  public languagePolandChoosen() {
    i18n.locale = "pl";
    this.$store.commit("SET_LANGUAGE", i18n.locale);
    this.onClose();
  }

  public languageEnglishChoosen() {
    i18n.locale = "en";
    this.$store.commit("SET_LANGUAGE", i18n.locale);
    this.onClose();
  }

  get isModalOpen() {
    return this.$store.state.isModalOpen;
  }
}
