






import { Component, Vue } from "vue-property-decorator";
import Menu from "./Menu.vue";

@Component({
  components: {
    Menu
  }
})
export default class Navigation extends Vue {}
