import Vue from 'vue';
import VueI18n from 'vue-i18n';
import App from './App.vue';
import router from './router';
import store from './store';
import { messages, defaultLocale } from './translations';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import {
  Swiper,
  EffectFade,
  EffectCoverflow,
  Pagination,
  Navigation
} from 'swiper';
import {
  faFacebook,
  faFacebookSquare
} from '@fortawesome/free-brands-svg-icons';
import {
  faAt,
  faPhoneAlt,
  faDownload,
  faMapMarkerAlt,
  faPills,
  faBoxOpen,
  faLeaf,
  faTint,
  faHardHat,
  faCalendarCheck,
  faBriefcase,
  faMicroscope,
  faBalanceScale,
  faBuilding,
  faUserTie,
  faClock,
  faInfoCircle,
  faTimes,
  faGlobe,
  faFax
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
/* eslint-disable */
library.add(
  faMapMarkerAlt,
  faAt,
  faDownload,
  faPhoneAlt,
  faPills,
  faBoxOpen,
  faLeaf,
  faTint,
  faHardHat,
  faCalendarCheck,
  faBriefcase,
  faMicroscope,
  faBalanceScale,
  faBuilding,
  faUserTie,
  faClock,
  faInfoCircle,
  faTimes,
  faGlobe,
  faFacebook,
  faFacebookSquare,
  faFax
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Swiper.use([EffectFade, EffectCoverflow, Pagination, Navigation]);
Vue.use(VueAwesomeSwiper);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyB3MKO57w0guUNqFa5OWGAt9R3rryW0KMQ',
    libraries: 'places' // necessary for places input
  }
});

Vue.use(VueI18n);

export const i18n = new VueI18n({
  messages,
  locale: defaultLocale,
  fallbackLocale: defaultLocale
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
