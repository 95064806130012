































import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class RibbonItem extends Vue {
  @Prop()
  direction;
  @Prop()
  title;
  @Prop()
  description;
  @Prop()
  image;

  get ribbonBackgroundImage() {
    return {
      "background-image": "url(" + require(`@/assets/${this.image}`) + ")",
    };
  }
}
