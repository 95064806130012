<template>
  <div class="offer-page">
    <OffersComponent/>
  </div>
</template>


<script>
import OffersComponent from "@/components/OffersComponent.vue";

export default {
  name: "OfferPage",
  components: {
    OffersComponent
  }
};
</script>