










import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class MaterialsItem extends Vue {
  @Prop()
  name;
  @Prop()
  icon;
  @Prop()
  imageClass;
}
