




















import { Component, Vue, Watch } from "vue-property-decorator";
import MaterialsItem from "./MaterialsItem.vue";
import { TranslateResult } from "vue-i18n";

@Component({
  components: {
    MaterialsItem,
  },
})
export default class MaterialsContainer extends Vue {
  materials = [
    {
      name: this.$t("mainPage.researchContent.firstItem") as TranslateResult,
      icon: "tint",
      imageClass: "liquid",
    },
    {
      name: this.$t("mainPage.researchContent.secondItem") as TranslateResult,
      icon: "tint",
      imageClass: "powder",
    },
    {
      name: this.$t("mainPage.researchContent.thirdItem") as TranslateResult,
      icon: "pills",
      imageClass: "pills",
    },
    {
      name: this.$t("mainPage.researchContent.fourthItem") as TranslateResult,
      icon: "leaf",
      imageClass: "leaf",
    },
  ];

  @Watch("$store.state.language")
  onPropertyChanged(value: string, oldValue: string) {
    this.materials = [
      {
        name: this.$t("mainPage.researchContent.firstItem") as TranslateResult,
        icon: "tint",
        imageClass: "liquid",
      },
      {
        name: this.$t("mainPage.researchContent.secondItem") as TranslateResult,
        icon: "tint",
        imageClass: "powder",
      },
      {
        name: this.$t("mainPage.researchContent.thirdItem") as TranslateResult,
        icon: "pills",
        imageClass: "pills",
      },
      {
        name: this.$t("mainPage.researchContent.fourthItem") as TranslateResult,
        icon: "leaf",
        imageClass: "leaf",
      },
    ];
  }
}
